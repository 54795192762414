import React from "react"
import CreateProfileForm from "../../components/forms/createprofile"
import Layout from "../../components/layouts/layout"

const NewClient = () => {
	return (
		<Layout title="Create Client Profile">
			<CreateProfileForm
				source="/client/new"
				roles="Client"
			/>
    </Layout>
  )
}

export default NewClient